<template>
	<div class="page">
		<!-- 音频 -->
		<audio controls ref="commonaudio" class="disnone">
			<source src="" />
		</audio>
		<!-- 音频 -->
		<div class="w788">
			<topHeader @goBack="goback()"></topHeader>
			<div class="top-col">今日需学习 <b>{{studyobj.need}}/{{studyobj.all}}</b></div>
		</div>
		<div class="w788">
			<!-- 卡片1 -->
			<div class="word-panel" v-if="wordobj.word&&learnNum==1">
				<div class="flex-col">
					<div class="master-left">
						<div class="flex-col">
							<div class="master">
								<span>{{wordobj.word}}</span>
								<div class="box-showdow"></div>
							</div>
						</div>
						<div class="linsten-block">
							<div class="linsten-btn" @click="playAudio(wordobj.phAmMp3)">
								<img src="@/assets/static/linsten.png" mode="" />
							</div>
							<span v-if="wordobj.phAm">美 /{{wordobj.phAm}}/</span>
						</div>
						<!-- 词描述 -->
						<div style="margin-top: 10px;">
							<div class="desc-item" v-for="(item,index) in wordobj.wordTpye" :key="index">
								<span>{{ item.part }}</span>
								<span class="desc2" v-for="(mitem, mindex) in item.means"
									:key="mindex">{{ mitem }}<span v-html="'&nbsp;'"></span>
								</span>
							</div>
						</div>
					</div>
					<div class="word-pic"><myImg :src="wordobj.img1"></myImg></div>
				</div>
				<div class="mid-line"></div>
				<div class="flex-col">
					<div class="master1 master">
						<span>自然拼读，快速记忆</span>
						<div class="box-showdow"></div>
					</div>
				</div>
				<!-- 音标列表 -->
				<div class="say-list" v-if="wordobj.tags.length>0">
					<div class="say-item" v-if="wordobj.tags[0]" :style="{background: colorlist[0]}">{{wordobj.tags[0]}}</div>
					<template v-for="(item,index) in wordobj.tags" v-if="index != 0">
						<div :key="index" class="line">-</div>
						<div class="say-item" v-if="index<=5" :style="{background: colorlist[index]}">{{item}}</div>
						<div class="say-item" v-if="index>5" :style="{background: colorlist[0]}">{{item}}</div>
					</template>
				</div>
				<div class="mid-line"></div>
				<!-- 点亮按钮 -->
				<div class="light-btn-col">
					<div class="btn" :class="{'opacity_btn':showlight==0}" @click="firstNext()">
						<img src="@/assets/static/light_icon.png" alt="" srcset="" />
						<span>{{wordobj.word}}</span>
					</div>
					<div class="right-tip">学习完成，点亮单词</div>
				</div>
			</div>
			<!-- 卡片学习2，选择题目 -->
			<div class="word-panel2" v-if="wordobj.word&&learnNum==2">
				<!-- 单词 -->
				<div class="master">
					<span>{{wordobj.word}}</span>
					<div class="box-showdow"></div>
				</div>
				<!-- 读音 -->
				<div class="linsten-block">
					<div class="linsten-btn" @click="playAudio(wordobj.phAmMp3)">
						<img src="@/assets/static/linsten.png" mode="" />
					</div>
					<span v-if="wordobj.phAm">美 /{{wordobj.phAm}}/</span>
				</div>
				<!-- 例图 -->
				<div class="word-img"><myImg :src="wordobj.img1"></myImg></div>
				<div class="mid-line"></div>
				<div class="s-tip">请从下列4个选择中选择正确的词义</div>
				<div class="select-list">
					<div v-for="(item,index) in optionsList" :key="index">
						<div v-if="item.optionName" class="select-item" :class="{'error':optionsindex==index&&optionsflag==2,'right':optionsindex>=0&&wordobj.word==item.word}" @click="handleoption(index)">
							<img v-if="optionsindex>=0&&optionsindex==index&&optionsflag==2" class="select-icon" src="@/assets/static/come_on.png"></img>
							<img v-if="optionsindex>=0&&wordobj.word==item.word" class="select-icon" src="@/assets/static/great.png" ></img>
							<span class="clamp">{{optionstext[index]}}. <span v-html="item.optionName"></span></span>
						</div>
						<div v-else class="select-item" :class="{'error':optionsindex==index&&optionsflag==2,'right':optionsindex>=0&&wordobj.word==item.word}" @click="handleoption(index)">
							<img v-if="optionsindex>=0&&optionsindex==index&&optionsflag==2" class="select-icon" src="@/assets/static/come_on.png"></img>
							<img v-if="optionsindex>=0&&wordobj.word==item.word" class="select-icon" src="@/assets/static/great.png" ></img>
							<span class="clamp">{{optionstext[index]}}. <span>其他三个都错误</span></span>
						</div>
						<!-- <div class="select-item error">
							<img class="select-icon" src="@/assets/static/come_on.png" />
							<span>B. 香蕉</span>
						</div>
						<div class="select-item right">
							<img class="select-icon" src="@/assets/static/great.png" />
							<span>A. 苹果</span>
						</div> -->
					</div>
				</div>
			</div>
			<!-- 卡片学习3，认识与否 -->
			<!-- 卡片 -->
			<div class="word-panel3" v-if="wordobj.word&&learnNum==3">
				<!-- 单词 -->
				<div class="master">
					<span>{{wordobj.word}}</span>
					<div class="box-showdow"></div>
				</div>
				<!-- 读音 -->
				<div class="linsten-block">
					<div class="linsten-btn" @click="playAudio(wordobj.phAmMp3)">
						<img src="@/assets/static/linsten.png" mode="" />
					</div>
					<span v-if="wordobj.phAm">美 /{{wordobj.phAm}}/</span>
				</div>
				<!-- 解释 -->
				<!-- 词描述 -->
				<div style="margin-top: 10px;">
					<div class="desc-item" v-for="(item,index) in wordobj.wordTpye" :key="index">
						<span>{{ item.part }}</span>
						<span class="desc2" v-for="(mitem, mindex) in item.means"
							:key="mindex">{{ mitem }}<span v-html="'&nbsp;'"></span>
						</span>
					</div>
				</div>
				<!-- 例图 -->
				<div class="word-img"><myImg :src="wordobj.img1"></myImg></div>
				<div class="mid-line"></div>
				<div class="s-tip">根据例句回忆单词含义</div>
				<!-- 例句 -->
				<div class="sentence" v-html="sentence"></div>
				<div class="look-desc" v-if="!showsentence" @click="showsentence = true">点击查看释义。</div>
				<div class="mean" v-else>{{sentence_cn}}</div>
				<div class="two-btn">
					<div @click="userReviewWord(2)">不认识</div>
					<div @click="userReviewWord(1)">认识</div>
				</div>
			</div>
			<div class="btn-group" v-if="wordobj.word">
				<img @click="handlexiaoci" src="@/assets/static/cancel_word_btn.png" desc="消词" alt="" />
				<img @click="clickclose(true)" src="@/assets/static/star_icon_checked.png" v-if="wordobj.ifcollect" desc="已收藏" alt="" />
				<img @click="clickclose(true)" src="@/assets/static/star_icon.png" v-else desc="未收藏" alt="" />
			</div>
		</div>
		<!-- 学完的弹窗 -->
		<el-dialog :show-close="false" width="539px" top="18vh" :visible.sync="isShowGreat" :close-on-click-modal="false">
			<div class="bodycon">
				<div class="dialog-text2"></div>
				<img class="finger_great" src="@/assets/static/finger_great.png" alt="" />
				<div class="dialog-text2">真棒，已成功认识这个单词！</div>
				<div class="dialog-text1"></div>
			</div>
		</el-dialog>
		<!-- 学完的弹窗 -->
		<el-dialog :show-close="false" width="539px" :visible.sync="learnFinish" :close-on-click-modal="false">
			<div class="bodycon">
				<img class="finger_great" src="@/assets/static/finger_great.png" alt="" />
				<div class="dialog-text1" v-if="studyingData.successwords">已新学 {{studyingData.successwords.length}} 个单词</div>
				<div class="dialog-text2">拼写单词可以强化记忆哦，请记得去拼写！</div>
				<div class="dialog-sub-btns">
					<div @click="sysgoback()">放弃拼写</div>
					<div @click="toWriteWords">开始拼写</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :show-close="false" width="680px" :visible.sync="isWordDetail" top="0vh" :close-on-click-modal="false">
			<wordCardCom class="word-card" :wordobj="wordCard"></wordCardCom>
			<div class="next-one" @click="handlenewword">下一词</div>
		</el-dialog>
		<!-- 收藏 -->
		<collectWord :isShow="isShowcollec" :list="collectList" @clickclose="clickclose" @handlecollect="handlecollect"></collectWord>
	</div>
</template>

<script>
	import topHeader from "@/components/topHeader/topHeader.vue";
	import wordCardCom from "@/components/wordCard/wordCard.vue";
	//引入状态管理
	import {mapState,mapMutations} from 'vuex'
	import collectWord from "@/components/collectWord/collectWord.vue";
	export default {
		components: {
			topHeader,
			wordCardCom,
			collectWord
		},
		data() {
			return {
				isWordDetail: false,
				learnNum: 1,//第几关
				bgnum: 0, //顶部渐变
				studyobj:{
					need:0,
					all:0,
				},
				exchangetext:{
					word_pl:"复数",
					word_third:"三单",
					word_past:"过去式",
					word_done:"过去分词",
					word_ing:"现在分词",
					word_adj:"形容词",
				},//词态
				colorlist:["#0EEC9C","#FFBC0F","#0EB7EC","#AA56FF","#FF434E","#FF439D","#0E82EC"],
				wordList:[],//单词列表
				wordobj:{},//当前学习的下标
				wordindex:0,//当前学习的下标
				backmsg:"当前学习状态中，确定要退出？",//当前学习状态中，确定要退出？
				startTime: 0,
				endTime: 0,
				timer: null,
				collectList:[],//收藏本集合
				isShowcollec:false,//展示收藏
				showlight:0,//亮灯
				studyingData:{},//新学习
				wordCard:null,
				awtitloading:false,//true时候无法点击
				optionstext:['A','B','C','D'],//选项列表
				optionsList:[],//选项列表
				optionsindex:-1,//选择的下标
				optionsflag:0,//选择正确 1是正确， 2是错误
				worddetailobj:{},//当前学习单词详情，
				learnFinish: false, // 是否学完了
				showsentence:false,//展示中文
				sentence: '',//第一个例句
				sentence_cn:'',//中文解释
				isShowGreat:false,//三次认识great
			};
		},
		//状态值
		computed: {
			...mapState(['myReadingObj','userInfo', 'isLogin']),
		},
		created() {
			this.studyobj.all = this.myReadingObj.perDayNum
			this.studyobj.need = this.myReadingObj.readNum || 0
			//今日要学习
			this.gettodayRead()
			//开始时间
			this.startTime = new Date().getTime()
			// this.timer = setInterval(() => {
			//     this.endTime = new Date().getTime();
			// }, 1000);
		},
		beforeDestroy() {
			//clearInterval(this.timer);
			//学习时间
			this.endTime = new Date().getTime();
			if (this.endTime > this.startTime) {
			    var miu = Math.floor((this.endTime - this.startTime) / 1000 / 60);
				var params = {
					userUuid:this.uuid,
					studyTime:miu>0?miu:1
				}
				this.$http.post('app/word/userReading/saveStudyTime', params).then(function(res) {
					
				})
			}
		},
		methods: {
			...mapMutations(['SET_Data']),
			//返回
			goback(){
				var _this = this
				_this.$confirm("您当前处于学习状态中，确定返回？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//返回
					_this.sysgoback()
				}).catch(() => {});
			},
			// 跳转开始拼写
			toWriteWords() {
				this.SET_Data({ key: 'studyingData', value:this.studyingData})
				this.$router.push({
					name:'wordSpelling'
				});
			},
			//第一次学习下一个点亮
			firstNext(){
				var _this = this
				if(this.showlight==1){
					return;
				}
				if(this.showlight==2){
					this.isWordDetail = true
					return;
				}
				this.showlight = 1
				//单词卡详情
				let findword = this.studyingData.detailwordList.find(item => item.id == this.wordobj.wordId);
				if(findword){
					this.wordCard = findword
				}else{
					this.getworddetail()
				}
				var timenext = setTimeout(function(){
					clearTimeout(timenext)
					_this.showlight = 2
					_this.isWordDetail = true
				},800)
			},
			//刷新页面单词
			refreshpage(){
				this.isWordDetail = false
				this.awtitloading = false
				if(this.learnNum ==1){
					this.showlight = 0
					this.wordList = this.studyingData.firstwords
					this.wordobj = this.studyingData.firstwords[0]
					//播放
					this.playAudio(this.wordobj.phAmMp3)
					//收藏本集合
					this.getcollect()
				}else if(this.learnNum ==2){
					this.wordList = this.studyingData.secondwords
					this.wordobj = this.studyingData.secondwords[0]
					this.optionsList = [] //选项列表
					this.optionsindex = -1 //选择的下标
					this.optionsflag = 0//选择正确 1是正确， 2是错误
					//播放
					this.playAudio(this.wordobj.phAmMp3)
					//先看看缓存有没有选项集合
					if(this.studyingData.wordoptions[this.wordobj.id]){
						this.optionsList = this.studyingData.wordoptions[this.wordobj.id] //选项列表
					}else{
						//单词对应选项
						this.wordSelectoptions()
					}
					//收藏本集合
					this.getcollect()
				}else if(this.learnNum ==3){
					this.showsentence = false//展示中文
					this.wordList = this.studyingData.thirdwords
					this.wordobj = this.studyingData.thirdwords[0]
					//播放
					this.playAudio(this.wordobj.phAmMp3)
					//详情
					let findword = this.studyingData.detailwordList.find(item => item.id == this.wordobj.wordId);
					if(findword){
						this.worddetailobj = JSON.parse(JSON.stringify(findword))
						//例句
						try{
							this.worddetailobj.sentence = JSON.parse(this.worddetailobj.sentence)
						}catch(e){
							this.worddetailobj.sentence = []
						}
						if(this.worddetailobj.sentence&&this.worddetailobj.sentence.length>0){
							this.sentence = this.worddetailobj.sentence[0].name || this.worddetailobj.sentence[0].en //第一个例句
							this.sentence_cn = this.worddetailobj.sentence[0].cn //中文解释
						}
					}
					//收藏本集合刷新
					this.getcollect()
				}else{}
			},
			//单词卡 下一词
			handlenewword(){
				var _this = this
				if(this.awtitloading){
					console.log("awtitloading")
					return false;
				}else{
					this.awtitloading = true
				}
				var studyingData = this.studyingData
				if(this.learnNum==1){
					let firstwords = studyingData.firstwords.filter(item => item.wordId !== _this.wordobj.wordId);
					studyingData.firstwords = firstwords
					if(firstwords.length>0){
						//还有词，继续下一个，如果没有了就进入第二关
						this.studyingData = studyingData
						//刷新页面单词
						this.refreshpage()
					}else{
						studyingData.secondwords = studyingData.oriwords
						this.studyingData = studyingData
						this.wordobj = {}
						//进入第二关
						this.learnNum = 2
						//刷新页面单词,进入第二关
						this.refreshpage()
					}
				}else if(this.learnNum==2){
					//第二关
					let secondwords = studyingData.secondwords.filter(item => item.wordId !== _this.wordobj.wordId);
					studyingData.secondwords = secondwords
					if(secondwords.length>0){
						//还有词，继续下一个，如果没有了就进入第二关
						this.studyingData = studyingData
						//刷新页面单词
						this.refreshpage()
					}else{
						if(studyingData.failwords.length>0){
							//有错误的
							console.log("有错误的",studyingData.failwords)
							studyingData.firstwords = studyingData.failwords
							studyingData.oriwords = studyingData.failwords
							studyingData.failwords = []
							//返回第一关
							this.studyingData = studyingData
							this.wordobj = {}
							this.learnNum = 1
							//刷新页面单词
							this.refreshpage()
						}else{
							//里面有词，说明第三关还没玩过
							if(studyingData.thirdwords.length<=0){
								studyingData.thirdwords = studyingData.oriwords
							}
							this.studyingData = studyingData
							this.wordobj = {}
							this.learnNum = 3
							//刷新页面单词
							this.refreshpage()
						}
					}
				}else if(this.learnNum==3){
					//第三关
					let thirdwords = studyingData.thirdwords.filter(item => item.wordId !== _this.wordobj.wordId);
					studyingData.thirdwords = thirdwords
					if(thirdwords.length>0){
						//还有词，继续下一个，如果没有了就完成进入听写
						this.studyingData = studyingData
						//刷新页面单词
						this.refreshpage()
					}else{
						//有错误的
						if(studyingData.failwords.length>0){
							console.log("有错误的",studyingData.failwords)
							studyingData.firstwords = studyingData.failwords
							studyingData.oriwords = studyingData.failwords
							studyingData.failwords = []
							//加入第三关，以免第二关错误集覆盖
							studyingData.thirdwords = studyingData.oriwords
							this.studyingData = studyingData
							//返回第一关
							this.wordobj = {}
							this.learnNum = 1
							//刷新页面单词
							this.refreshpage()
						}else{
							//跳转到听听写
							this.learnFinish = true
						}
					}
				}else{}
			},
			//认识或者不认识
			async userReviewWord(type){
				var _this = this
				if(this.awtitloading){
					console.log("awtitloading")
					return false;
				}else{
					this.awtitloading = true
				}
				var studyingData = this.studyingData
				if(type == 1){
					//认识通过
					this.isShowGreat = true
					const params = {
						wordId:this.wordobj.wordId,
						bookId:this.wordobj.bookId,
						userUuid:this.userInfo.uuid
					}
					await this.$common.userReviewWord(params)
					this.getMyReading()
					studyingData.successwords.push(this.wordobj)
					studyingData.curnum +=1
					this.studyingData = studyingData
				}else{
					//不认识
					//取值放到错误里面
					studyingData.failwords.push(this.wordobj)
					this.studyingData = studyingData
					//调用错词
					const params = {
						wordId:this.wordobj.wordId,
						bookId:this.wordobj.bookId,
						userUuid:this.userInfo.uuid
					}
					await this.$common.userErrWord(params)
				}
				this.awtitloading = false
				//单词卡详情
				let findword = this.studyingData.detailwordList.find(item => item.id == this.wordobj.wordId);
				if(findword){
					this.wordCard = findword
				}else{
					this.getworddetail()
				}
				if(type == 1){
					var timenext = setTimeout(function(){
						clearTimeout(timenext)
						_this.isShowGreat = false
						_this.isWordDetail = true
					},800)
				}else{
					_this.isShowGreat = false
					_this.isWordDetail = true
				}
			},
			//选择题目
			async handleoption(index){
				var _this = this
				if(this.awtitloading){
					console.log("awtitloading")
					return false;
				}else{
					this.awtitloading = true
				}
				if(this.wordobj.word==this.optionsList[index].word){
					this.optionsflag = 1
				}else{
					this.optionsflag = 2
					//取值放到错误里面
					var studyingData = this.studyingData
					studyingData.failwords.push(this.wordobj)
					this.studyingData = studyingData
					//调用答错词
					const params = {
						wordId:this.wordobj.wordId,
						bookId:this.wordobj.bookId,
						userUuid:this.userInfo.uuid
					}
					await this.$common.userErrWord(params)
				}
				this.optionsindex = index
				//单词卡详情
				let findword = this.studyingData.detailwordList.find(item => item.id == this.wordobj.wordId);
				if(findword){
					this.wordCard = findword
				}else{
					this.getworddetail()
				}
				var timenext = setTimeout(function(){
					clearTimeout(timenext)
					_this.awtitloading = false
					_this.isShowGreat = false
					_this.isWordDetail = true
				},800)
			},
			//单词对应选项
			wordSelectoptions(){
			 	var _this = this
				var params = {
					lessonWordId:this.wordobj.id,
				}
				this.$http.post('app/word/userReading/wordSelect', params).then(function(res) {
					if (res.code === 200) {
						if(res.data.length>0){
							var records = _this.shuffleArray(res.data)
							//处理词义,去除来
							records.forEach((item, index) => {
								item["optionName"] = ""
								try{
									var definition = JSON.parse(item.definition)
									if(definition&&definition.length>0){
										item["optionName"] = definition[0].part + definition[0].means.join(' ')
									}
								}catch(e){}
							});
							//存到缓存，下次不用请求
							var studyingData = _this.studyingData
							if(!studyingData.wordoptions[_this.wordobj.id]){
								_this.$set(studyingData.wordoptions, _this.wordobj.id, records);
								_this.studyingData = studyingData
							}
							_this.optionsList = records
						}else{
							_this.$confirm("选项为空！", '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//打乱顺序
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
				  // 生成[0, i]范围内的随机索引
				  const j = Math.floor(Math.random() * (i + 1));
				  // 交换array[i]和array[j]
				  [array[i], array[j]] = [array[j], array[i]];
				}
				return array;
			},
			//今日要学习
			gettodayRead(){
			 	var _this = this
				var params = {
					userUuid:this.userInfo.uuid,
					perDayNum:this.myReadingObj.perDayNum
				}
				this.$http.post('app/word/userReading/todayRead', params).then(function(res) {
					if (res.code === 200) {
						var records = res.data
						if(records&&records.length>0){
							var myReadingObj = _this.myReadingObj
							myReadingObj.perDayNum = records.length
							_this.studyobj.all = myReadingObj.perDayNum
							_this.SET_Data({ key: 'myReadingObj', value:myReadingObj})
							records.forEach((item, index) => {
								try{
									item["wordTpye"] = JSON.parse(item.definition)
								}catch(e){
									item["wordTpye"] = []
									//TODO handle the exception
								}
								try{
									item.exchange = JSON.parse(item.exchange)
								}catch(e){
									item.exchange = {}
								}
								try{
									item.tags = item.syllables.split('#')
								}catch(e){
									item.tags = []
								}
							});
							//过滤掉消词和学完的
							var newrecords = records.filter(item => !item.userReviewId&&!item.userSuccessId);
							console.log("需要学习量",newrecords.length)
							if(newrecords.length<=0){
								_this.$confirm("今日需学习已经完成，请返回！", '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									//返回
									_this.sysgoback()
								}).catch(() => {});
								return false;
							}
							//新学习
							var studyingData = {
								detailwordList:[],//详情集合
								wordoptions:{},//答案选项集合
								todaywords:records,//全部单词
								oriwords:newrecords,
								curnum:_this.myReadingObj.readNum,//
								firstwords:newrecords,//第一次推送的词
								secondwords:[],//第二次的词
								thirdwords:newrecords,//第san次的词
								failwords:[],//错误的放到这里，返回第一关
								successwords:[],//完成的反这里
							}
							_this.studyingData = studyingData
							_this.wordobj = newrecords[0]
							_this.wordList = newrecords
							//收藏本集合
							_this.getcollect()
							//播放
							_this.playAudio(_this.wordobj.phAmMp3)
						}else{
							_this.$confirm("今日需学习已经完成，请返回！", '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								//返回
								_this.sysgoback()
							}).catch(() => {});
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//详情
			getworddetail(){
				var _this = this
				var params = {
					id: this.wordobj.wordId
				}
				this.$http.post('app/word/words/detail', params).then(function(res) {
					if (res.code == 200) {
						var wordCard = res.data
						if(wordCard){	
							//查看是否存在，没有就加入进去
							_this.checkwordData(wordCard)
							_this.wordCard = wordCard
						}else{
							_this.$message.error("单词卡不存在，请返回重试")
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})			
			},
			//查看是否存在，没有就加入进去
			checkwordData(obj){
				var studyingData = this.studyingData
				let findword = studyingData.detailwordList.find(item => item.id == this.wordobj.wordId);
				if(findword){
					return findword;
				}else{
					studyingData.detailwordList.push(obj)
					this.studyingData = studyingData
				}
			},
			//收藏本集合
			getcollect(){
			 	var _this = this
				var params = {
					userUuid:this.userInfo.uuid,
					wordId:this.wordobj.wordId
				}
				this.$http.post('app/word/userCollectBooks/indexWord', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data
						_this.wordobj["ifcollect"] = false
						records.forEach((item, index) => {
							if(item.collectBookWordId&&item.collectBookWordId>0){
								item["ifcollect"] = true
								_this.wordobj["ifcollect"] = true
							}else{
								item["ifcollect"] = false
							}
						});
						_this.collectList = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//收藏弹窗
			clickclose(type){
				this.isShowcollec = type
			},
			//确认收藏
			async handlecollect(list){
				var _this = this
				var params = []
				list.forEach((item, index) => {
					if(item.ifcollect){
						params.push({
							collectBookId: item.id,
							collectStatus: 1,//是否收藏 0否 1是
							wordId: _this.wordobj.wordId
						})
					}else{
						params.push({
							collectBookId: item.id,
							collectStatus: 0,//是否收藏 0否 1是
							wordId: _this.wordobj.wordId
						})
					}
				});
				var res = await this.$common.userCollectBooksWord(params)
				if(res){
					this.clickclose(false)
					this.$message.success("操作成功！")
					//收藏本集合刷新
					this.getcollect()
				}
			},
			//消词
			async handlexiaoci(){
			 	var _this = this
				_this.$confirm("消灭单词("+_this.wordobj.word+")之后不再进行该单词学习和复习，确定要消灭单词吗？", '消灭单词', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async() => {
					//调用消词
					let params = {
						wordId:_this.wordobj.wordId,
						bookId:_this.wordobj.bookId,
						userUuid:_this.userInfo.uuid,
						wordType:1,//	完成类型 0完成 1消除
					}
					let res = await _this.$common.userSuccessWord(params)
					_this.$message.success("消词成功！")
					if(res){
						//刷新首页信息
						_this.getMyReading()
						var studyingData = _this.studyingData
						studyingData.successwords.push(_this.wordobj)
						studyingData.curnum +=1
						_this.studyobj.need += 1 
						//第一关消词
						if(_this.learnNum == 1){
							let firstwords = studyingData.firstwords.filter(item => item.wordId !== _this.wordobj.wordId);
							studyingData.firstwords = firstwords
							let oriwords = studyingData.oriwords.filter(item => item.wordId !== _this.wordobj.wordId);
							studyingData.oriwords = oriwords
							let thirdwords = studyingData.thirdwords.filter(item => item.wordId !== _this.wordobj.wordId);
							studyingData.thirdwords = thirdwords
							_this.studyingData = studyingData
							//判断是不是第一关最后一个单词
							if(studyingData.firstwords.length<=0){
								studyingData.secondwords = studyingData.oriwords
								_this.studyingData = studyingData
								_this.wordobj = {}
								//进入第二关
								_this.learnNum = 2
								//刷新页面单词,进入第二关
								_this.refreshpage()
							}else{
								_this.refreshpage()
							}
						}else if(_this.learnNum == 2){
							//第二关消词
							let secondwords = studyingData.secondwords.filter(item => item.wordId !== _this.wordobj.wordId);
							studyingData.secondwords = secondwords
							let oriwords = studyingData.oriwords.filter(item => item.wordId !== _this.wordobj.wordId);
							studyingData.oriwords = oriwords
							let thirdwords = studyingData.thirdwords.filter(item => item.wordId !== _this.wordobj.wordId);
							studyingData.thirdwords = thirdwords
							_this.studyingData = studyingData
							//判断是不是第二关最后一个单词
							if(studyingData.secondwords.length<=0){
								//有错误的
								if(studyingData.failwords.length>0){
									//有错误的
									console.log("有错误的",studyingData.failwords)
									studyingData.firstwords = studyingData.failwords
									studyingData.oriwords = studyingData.failwords
									studyingData.failwords = []
									//返回第一关
									_this.studyingData = studyingData
									_this.wordobj = {}
									_this.learnNum = 1
									//刷新页面单词
									_this.refreshpage()
								}else{
									//里面有词，说明第三关还没玩过
									if(studyingData.thirdwords.length<=0){
										studyingData.thirdwords = studyingData.oriwords
									}
									_this.studyingData = studyingData
									_this.wordobj = {}
									_this.learnNum = 3
									//刷新页面单词
									_this.refreshpage()
								}
							}else{
								_this.refreshpage()
							}
						}else if(_this.learnNum == 3){
							let thirdwords = studyingData.thirdwords.filter(item => item.wordId !== _this.wordobj.wordId);
							studyingData.thirdwords = thirdwords
							let oriwords = studyingData.oriwords.filter(item => item.wordId !== _this.wordobj.wordId);
							studyingData.oriwords = oriwords
							_this.studyingData = studyingData
							//判断是不是最后一个单词
							if(studyingData.thirdwords.length<=0){
								//有错误的
								if(studyingData.failwords.length>0){
									console.log("有错误的",studyingData.failwords)
									studyingData.firstwords = studyingData.failwords
									studyingData.oriwords = studyingData.failwords
									studyingData.failwords = []
									//加入第三关，以免第二关错误集覆盖
									studyingData.thirdwords = studyingData.oriwords
									_this.studyingData = studyingData
									//返回第一关
									_this.wordobj = {}
									_this.learnNum = 1
									//刷新页面单词
									_this.refreshpage()
								}else{
									//跳转到听听写
									_this.learnFinish = true
								}
							}else{
								_this.refreshpage()
							}
						}else{}
					}
				}).catch(() => {});
			},
			//播放音频
			playAudio(src) {
				var _this = this
				try{
					_this.$refs.commonaudio.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudio.load()
					    _this.$refs.commonaudio.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//首页学习计划
			getMyReading(){
				var _this = this
				//需要登录
				var params = {
					userUuid:this.userInfo.uuid
				}
				this.$http.post('app/word/userReading/getMyReading', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						if(res.data){
							//进度
							if(res.data.userBooksDTO.readNum!=0){
								res.data["progresstext"] = Number(((res.data.userBooksDTO.readNum / res.data.userBooksDTO.booksDTO.wordNum)  * 100).toFixed(0));
							}else{
								res.data["progresstext"] = 0
							}
							if(res.data.userBooksDTO.readNum>0){
								res.data.readStatus = 1
							}
							if(res.data.userBooksDTO.readNum>=res.data.userBooksDTO.booksDTO.wordNum){
								res.data.readStatus = 2
							}
							res.data["perDayNum"] = res.data.userBooksDTO.perDayNum
							_this.SET_Data({ key: 'myReadingObj', value:res.data})
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			
		},
	};
</script>

<style lang="scss" scoped>
	.dialog-text1 {
		font-weight: bold;
		margin-top: 27px;
		color: #5c3704;
		font-size: 24px;
	}
	
	.dialog-text2 {
		margin-top: 16px;
		color: #666666;
		font-size: 20px;
	}
	
	.dialog-sub-btns {
		margin-top: 44px;
		display: flex;
		align-items: center;
	
		div {
			width: 146px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			color: #fff;
			cursor: pointer;
			transition: 0.3s all;
	
			&:hover {
				opacity: 0.8;
			}
	
			&:first-child {
				background: #67adff;
			}
	
			&:last-child {
				margin-left: 20px;
				background: #51d9a5;
			}
		}
	}
	
	/deep/ .el-dialog {
		background: unset !important;
	}
	
	/deep/ .el-dialog__header {
		display: none;
	}
	
	/deep/ .el-dialog__body .bodycon{
		padding: 30px 20px;
		height: 100%;
		background: #fbf7f5;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.word-card {
		background: #fff;
	}
	.next-one {
		background: #67adff;
		box-shadow: 0px -2px 0px 0px #3e97fe inset;
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		width: 122px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 32px auto 0;
		border-radius: 40px;
		transition: 0.3s all;
		cursor: pointer;

		&:hover {
			background: #5890d1;
		}
	}

	.top-col {
		width: 100%;
		height: 58px;
		padding: 0 20px;
		display: flex;
		align-items: center;
		border: 1px solid #e5e5e5;
		margin-top: 12px;
		border-radius: 12px;
		color: #222222;
		font-size: 16px;

		b {
			margin-left: 4px;
		}
	}

	.mid-line {
		margin: 24px 0;
		border-bottom: 1px dashed #dbdbdb;
	}

	.word-panel {
		border-radius: 20px;
		min-height: 400px;
		background: #f7f7f7;
		padding: 32px;
		margin-top: 12px;
		position: relative;

		.say-list {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-top: 4px;
			font-size: 13px;
			.say-item {
				padding: 2px 6px;
				color: #fff;
				border-radius: 4px;
				margin-top: 6px;
			}
		
			.line {
				color: #999999;
				margin: 0 4px;
			}
		}

		.flex-col {
			display: flex;
			width: 100%;
			.master-left {
				display: flex;
				flex-direction: column;
				flex: 1;
			}
			.master1 {
				color: #5c3704 !important;
				font-size: 16px !important;
			}

			.master {
				color: #222;
				font-size: 34px;
				font-weight: bold;
				position: relative;
				display: flex;
				word-break: break-all;

				span {
					position: relative;
					z-index: 8;
				}

				.box-showdow {
					position: absolute;
					width: 98%;
					height: 10px;
					background: #fdf0cf;
					left: 3px;
					bottom: 5px;
					border-radius: 4px;
				}
			}

			.linsten-block {
				margin-top: 7px;
				display: flex;
				align-items: center;

				span {
					font-size: 16px;
					margin-left: 12px;
					color: #666;
				}
			}

			.linsten-btn {
				border-radius: 20px;
				border: 1px solid #e0e0e0;
				background: #fff;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				height: 24px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:hover {
					transform: scale(1.05);
				}

				img {
					width: 16px;
					height: 16px;
				}
			}
		}
		
		.desc-item {
			display: flex;
			flex-wrap: wrap;
			margin-top: 10px;
			line-height: 18px;
			color: #999999;
			&:first-child {
				font-size: 16px;
				color: #999999;
			}
			.desc2 {
				font-size: 14px;
				color: #222222;
				margin-left: 5px;
			}
		}

		.light-btn-col {
			display: flex;
			align-items: center;

			.btn {
				display: flex;
				align-items: center;
				justify-content: center;
				background: #fe8c22;
				box-shadow: 0px -2px 0px 0px #f07400 inset;
				width: 177px;
				height: 56px;
				border-radius: 56px;
				font-size: 16px;
				color: #ffffff;
				transition: 0.3s all;

				&:hover {
					opacity: 0.8;
				}

				span {
					margin-left: 4px;
					margin-top: 4px;
				}
			}

			.right-tip {
				margin-left: 12px;
				font-size: 14px;
				color: #723018;
			}
		}

		.word-pic {
			width: 263px;
			height: 136px;
			border-radius: 12px;
			overflow: hidden;
			flex-shrink: 0;
			margin-left: 10px;
		}
	}
	.word-panel2 {
		border-radius: 12px;
		min-height: 400px;
		padding: 32px;
		margin-top: 12px;
		position: relative;
		border: 1px solid #e5e5e5;
		display: flex;
		flex-direction: column;
		align-items: center;
	
		.master {
			color: #222;
			font-size: 34px;
			font-weight: bold;
			position: relative;
			display: flex;
			word-break: break-all;
	
			span {
				position: relative;
				z-index: 8;
			}
	
			.box-showdow {
				position: absolute;
				width: 100%;
				height: 10px;
				background: #fdf0cf;
				left: 3px;
				bottom: 6px;
				border-radius: 4px;
			}
		}
	
		.linsten-block {
			margin-top: 7px;
			display: flex;
			align-items: center;
	
			span {
				font-size: 16px;
				margin-left: 12px;
				color: #666;
			}
	
			.linsten-btn {
				border-radius: 20px;
				border: 1px solid #e0e0e0;
				background: #fff;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				height: 24px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
	
				&:hover {
					transform: scale(1.05);
				}
	
				img {
					width: 16px;
					height: 16px;
				}
			}
		}
	
		.desc-item {
			display: flex;
			margin-top: 15px;
			line-height: 18px;
			&:first-child {
				font-size: 16px;
				color: #999999;
			}
			.desc2 {
				font-size: 14px;
				color: #222222;
				margin-left: 5px;
			}
		}
	
		.word-img {
			width: 263px;
			height: 136px;
			border-radius: 12px;
			margin-top: 20px;
			overflow: hidden;
		}
	
		.mid-line {
			border-bottom: dashed 1px #dbdbdb;
			width: 298px;
			margin: 20px 0;
		}
	
		.s-tip {
			width: 263px;
			font-size: 15px;
			font-weight: bold;
			color: #5c3704;
		}
	
		.select-list {
			display: flex;
			flex-direction: column;
			width: 298px;
			margin-top: 16px;
	
			.select-item {
				display: flex;
				align-items: center;
				margin-bottom: 12px;
				height: 55px;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				background: #ffffff;
				border-radius: 8px;
				padding: 0 20px;
				position: relative;
				border: solid 1px #e5e5e5;
				font-weight: bold;
				cursor: pointer;
				font-size: 15px;
				&:hover {
					border: solid 1px #aaa;
				}
			}
			.error {
				background: #f85b59 !important;
				color: #fff !important;
			}
	
			.right {
				background: #51d9a5 !important;
				color: #fff !important;
			}
	
			.select-icon {
				height: 28px;
				position: absolute;
				right: 0;
				top: -31px;
				object-fit: contain;
			}
		}
	}
	.word-panel3 {
		border-radius: 12px;
		min-height: 400px;
		padding: 32px;
		margin-top: 20px;
		position: relative;
		border: 1px solid #e5e5e5;
		display: flex;
		flex-direction: column;
		align-items: center;
		.master {
			color: #222;
			font-size: 34px;
			font-weight: bold;
			position: relative;
			display: flex;
			word-break: break-all;
	
			span {
				position: relative;
				z-index: 8;
			}
	
			.box-showdow {
				position: absolute;
				width: 100%;
				height: 10px;
				background: #fdf0cf;
				left: 3px;
				bottom: 8px;
				border-radius: 4px;
			}
		}
	
		.linsten-block {
			margin-top: 7px;
			display: flex;
			align-items: center;
	
			span {
				font-size: 16px;
				margin-left: 12px;
				color: #666;
			}
	
			.linsten-btn {
				border-radius: 20px;
				border: 1px solid #e0e0e0;
				background: #fff;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				height: 24px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
	
				&:hover {
					transform: scale(1.05);
				}
	
				img {
					width: 16px;
					height: 16px;
				}
			}
		}
	
		.desc-item {
			display: flex;
			margin-top: 15px;
			line-height: 18px;
			&:first-child {
				font-size: 16px;
				color: #999999;
			}
			.desc2 {
				font-size: 14px;
				color: #222222;
				margin-left: 5px;
			}
		}
	
		.sentence {
			width: 358px;
			margin-top: 20px;
		}
	
		.look-desc {
			width: 358px;
			margin-top: 12px;
			font-size: 16px;
			font-weight: bold;
			color: #0075ff;
			cursor: pointer;
	
			&:hover {
				text-decoration: underline;
			}
		}
	
		.mean {
			font-size: 14px;
			margin-top: 12px;
			width: 358px;
			color: #666;
		}
	
		.word-img {
			width: 263px;
			height: 136px;
			border-radius: 12px;
			margin-top: 20px;
		}
	
		.mid-line {
			border-bottom: dashed 1px #dbdbdb;
			width: 358px;
			margin: 20px 0;
		}
	
		.s-tip {
			width: 358px;
			font-size: 14px;
			font-weight: bold;
			color: #5c3704;
		}
	
		.two-btn {
			width: 50%;
			display: flex;
			align-items: center;
			margin-top: 40px;
			justify-content: space-between;
			div {
				box-shadow: 0px 2px 6px 0px #0000001f;
				width: 158px;
				height: 40px;
				border-radius: 40px;
				color: #5c3704;
				cursor: pointer;
				font-size: 14px;
				font-weight: bold;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.3s all;
				&:hover {
					opacity: 0.8;
				}
	
				&:last-child {
					margin-left: 12px;
					background: #51d9a5;
					color: #fff;
				}
			}
		}
	}
	.btn-group {
		margin: 20px 0 40px;
		img {
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				opacity: 0.8;
				transform: scale(1.05);
			}
		}
	}
</style>